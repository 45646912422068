
import { computed, defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { CourseActions } from "@/store/vm/course/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import TinyMCE from "@/components/editor/tinyMCE.vue";
import CropperComponent from "@/../src/components/upload/cropper.vue";

export default defineComponent({
  name: "upsert-course",
  components: {
    tiny: TinyMCE,
    cropper: CropperComponent,
  },
  props: {
    courseIdProp: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["createSuccess", "updateSuccess"],
  methods: {
    activateCropper: function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs["imagesCropper"] as any).selectFile();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onUploadCoverFail: function (response) {
      Swal.fire({
        position: "top-end",
        text: "Đã xảy ra lỗi upload ảnh, vui lòng thử lại sau!",
        icon: "error",
        buttonsStyling: false,
        timer: 2000,
        width: 400,
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    // Get params from url
    const courseId = computed(() => {
      return props.courseIdProp;
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const content = ref<string>("");

    const defaultData = {
      course_id: "",
      name: "",
      description: "",
      type_code: "online",
      status: 0,
      detail: "",
      images: "",
      document: "",
      video: "",
      url: "",
      price: 0,
    };

    onMounted(async () => {
      // get detail
      if (courseId.value) {
        const detail = await store
          .dispatch(CourseActions.GET_DETAIL, courseId.value)
          .catch((e) => {
            console.log(e);
          });

        formData.value = detail;
        // extra
        content.value = detail.detail;
      }
    });

    const formData = ref({ ...defaultData });

    const statusLabels = [
      {
        value: 0,
        label: "Active",
      },
      {
        value: 1,
        label: "Inactive",
      },
    ];

    const typeCodeLabels = [
      {
        value: "online",
        label: "Chương trình học online",
      },
      {
        value: "offline",
        label: "Chương trình học offline",
      },
      {
        value: "video",
        label: "Khóa học video dựng sẵn",
      },
    ];

    const rules = ref({});
    const isCreate = !courseId.value;

    const actionCreate = (par) => {
      par.detail = content.value;
      return store.dispatch(CourseActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      par.id = courseId;
      par.detail = content.value;
      return await store.dispatch(CourseActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const onChangeContent = (newValue) => {
      formData.value.detail = newValue;
      content.value = newValue;
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;
          if (isCreate) {
            stepResult = await actionCreate(formData.value);
          } else {
            stepResult = await actionUpdate(formData.value);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(() => {
              if (isCreate) {
                emit("createSuccess");
              } else {
                emit("updateSuccess");
              }
            });
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const onUploadCoverSuccess = (imageUrl) => {
      formData.value.images = imageUrl;
    };

    return {
      courseId,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      content,
      onChangeContent,
      statusLabels,
      onUploadCoverSuccess,
      typeCodeLabels,
    };
  },
});
